import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import AgencyGuard from './guards/agency-guard'

export default [
  {
    path: '/agency/',
    component: DashboardLayout,
    beforeEnter: AgencyGuard,
    redirect: { name: 'agency.businesses.index' },
    children: [
      {
        name: 'agency.dashboard',
        path: 'dashboard',
        component: lazyLoading('Agency/Dashboard'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.index',
        path: 'businesses',
        component: lazyLoading('Agency/Businesses/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.archived',
        path: 'businesses/archived',
        component: lazyLoading('Agency/Businesses/Archived'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.edit',
        path: 'businesses/:id/edit',
        component: lazyLoading('Agency/Businesses/Edit'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.create',
        path: 'businesses/create',
        component: lazyLoading('Agency/Businesses/Create'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.payment-methods.index',
        path: 'payment-methods',
        component: lazyLoading('Agency/PaymentMethods/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.payment-methods.create',
        path: 'payment-methods/create',
        component: lazyLoading('Agency/PaymentMethods/Create'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.subscription',
        path: 'subscription',
        component: lazyLoading('Agency/Subscription'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.profile',
        path: 'profile',
        component: lazyLoading('Common/Profile'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.settings',
        path: 'settings',
        component: lazyLoading('Agency/Settings'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.training',
        path: 'training',
        component: lazyLoading('Agency/Training'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.landing',
        path: 'landing',
        component: lazyLoading('Agency/Landing'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.embed',
        path: 'embed',
        component: lazyLoading('Agency/Embed'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.reviews',
        path: 'reviews/:business',
        component: lazyLoading('Agency/Widgets/Reviews'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.widgets.index',
        path: 'widgets',
        component: lazyLoading('Agency/Widgets/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.widgets.archived',
        path: 'widgets/archived',
        component: lazyLoading('Agency/Widgets/Archived'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.widgets.edit',
        path: 'widgets/:id/edit',
        component: lazyLoading('Agency/Widgets/Edit'),
        beforeEnter: AgencyGuard,
      },
    ],
  },
]
