import Vue from 'vue'
import Vuex from 'vuex'

import appModule from './modules/app-module'
import authModule from './modules/auth-module'
import userModule from './modules/user-module'
import subscriptionModule from './modules/subscription-module'
import paymentMethodModule from './modules/payment-method-module'
import agencyModule from './modules/agency-module'
import businessModule from './modules/business-module'
import whitelabelModule from './modules/whitelabel-module'
import landingPageModule from "./modules/landingpage-module";
import widgetModule from './modules/widgets-module';
import reviewsModule from './modules/reviews-module';

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,
  modules: {
    app: appModule,
    auth: authModule,
    user: userModule,
    paymentMethod: paymentMethodModule,
    subscription: subscriptionModule,
    agency: agencyModule,
    business: businessModule,
    whitelabel: whitelabelModule,
    landingPage: landingPageModule,
    widgets: widgetModule,
    reviews: reviewsModule
  },
})

export default store
