import Vue from 'vue'
import axios from 'axios'

const initState = {
  landingpage: null,
  widget:null
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {

  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },
    find({ commit }, pageId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/landingpage/${pageId}`)
            .then((res) => {
              this.landingpage = res.data.data;
              resolve(res.data.data)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    getReviewGate({ commit }, pageUuid) {
      return new Promise((resolve, reject) => {
          axios
              .get(`/landingwidget/${pageUuid}`)
              .then((res) => {
                  this.widget = res.data.data;
                  resolve(res.data.data)
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },
      postNegativeExperience({ commit }, params){
          return new Promise((resolve,reject) => {
              axios
                  .post(`/add-feedback/${params.id}`,params)
                  .then((res) => {
                      resolve(res.data)
                  })
                  .catch((error) => {
                      reject(error)
                  })
          })
      }
  },
}
