import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  index: {
    reviews: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'active',
      prospect_status:'all',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
  archived: {
    reviews: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'archived',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { reviews, page, total, queries, meta }) {
      state[page] = {
        reviews: reviews,
        total: total,
        queries: queries,
        meta:meta
      }
    },

    updateSuccess(state, { id, reviews }) {

    },

    updateBroadcastersSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    currentPage(state, page) {
      state.currentPage = page
    },
  },

  actions: {
    getAll({ commit }, { queries, page, businessId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businessreviews/${businessId}`, { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              reviews: res.data.data,
              page: page,
              total: res.data.meta.total,
              queries: queries,
              meta: res.data.meta
            })
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    toggleVisibility({ commit },review){
      return new Promise((resolve, reject) => {
        axios
            .put(`review_visibility/${review.id}`, review)
            .then((res) => {
              commit('updateSuccess', {
                id: review.id,
                review: res.data.data,
              })
              resolve(res.data.data);
            })
            .catch((error) => {
              reject(error)
            })
      })
    },


    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    setCurrentPage({ commit }, page) {
      commit('currentPage', page)
    },

  },

  getters: {
    all(state) {
      return state[state.currentPage].reviews
    },

    queries(state) {
      return state[state.currentPage].queries
    },

    total(state) {
      return state[state.currentPage].total
    },

    meta(state) {
      return state[state.currentPage].meta
    },
  },
}
