import lazyLoading from './lazyLoading'
import LandingLayout from '@layouts/Landing'

export default [
  {
    path: '/p/',
    component:LandingLayout,
    redirect: { name: 'agency.landing.index' },
    children: [
      {
        path: ':id',
        component: lazyLoading('Agency/Landing/Index'),
        name: 'agency.landing.index',
      }
    ],
  },
  {
    path: '/linknotfound',
    component: lazyLoading('Agency/Landing/NotFound'),
    name: 'notfound',
  },
  {
    path: '/review/',
    component:LandingLayout,
    redirect: { name: 'gate.review' },
    children: [
      {
        path: ':uuid',
        component: lazyLoading('Business/ReviewGate'),
        name: 'gate.review',
      }
    ],
  },
]
